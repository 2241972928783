import React, { FC } from 'react';
import { PageProps } from 'gatsby';
import Seo from '../components/Seo';
import PageLayout from '../components/PageLayout';

const pageName = 'Contact';

const Contact: FC<PageProps> = ({ path }) => {
    return (
        <PageLayout path={path} >
            <Seo title={pageName} />
            <h1>This is {pageName} page</h1>
        </PageLayout>
    )
};

export default Contact;
